// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Colour highlights for amendment text
.highlight.rejected{
  color:red;
}

.highlight.actioned{
  color:green;
}

.highlight.pending{
  color:darkorange;
}


